<!-- 电力运行日报 -->
<template>
  <div id="extreme-report">
    <!-- <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="true"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div> -->
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>电力运行日报</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期" class="query-date">
              <el-date-picker placeholder="请选择日期"  :append-to-body="false" type="date"  value-format="yyyy-MM-dd" v-model="formData.time"></el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="电力类别:">
              <el-select v-model="formData.region" placeholder="请选择">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="回路名称">
              <el-select clearable :popper-append-to-body="false" v-model="formData.deviceNumber" placeholder="请选择">
                <el-option v-for="(item, index) in loopList" :key="index" :label="item.loopName +' ('+ item.loopNumber+ ')'"
                  :value="item.deviceNumber"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <!-- <el-button type="primary" icon="el-icon-arrow-left">上一日</el-button> -->
              <!-- <el-button type="primary">下一日<i class="el-icon-arrow-right el-icon--right"></i></el-button> -->
              <el-button type="primary" @click="getRunningList" icon="el-icon-search">查询</el-button>
              <!-- <el-button type="primary" icon="el-icon-download" @click="exportDialog = true">导出</el-button> -->
            </el-form-item>
          </el-form>
        </div>

        <el-table class="table"  :data="tableData">
          <el-table-column align="center" width="120" prop="nowTime" label="时间">
            <template slot-scope="scope">
              {{ scope.row.nowTime.substr(11, 8) }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="onceUa" label="A相电压(V)">
          </el-table-column>
          <el-table-column align="center" prop="onceUb" label="B相电压(V)">
          </el-table-column>
          <el-table-column align="center" prop="onceUc" label="C相电压(V)">
          </el-table-column>
          <el-table-column align="center" prop="onceIa" label="A相电流(A)">
          </el-table-column>
          <el-table-column align="center" prop="onceIb" label="B相电流(A)">
          </el-table-column>
          <el-table-column align="center" prop="onceIc" label="C相电流(A)">
          </el-table-column>
          <el-table-column width="150" align="center" prop="oncePe" label="有功功率(Wh)">
          </el-table-column>
          <el-table-column width="150" align="center" prop="onceQe" label="无功功率(var)">
          </el-table-column>
          <el-table-column width="150" align="center" prop="onceEpaddIns" label="正向有功电能(Wh)">
          </el-table-column>
          <el-table-column align="center" prop="onceCosQe" label="功率因数">
          </el-table-column>
         
        </el-table>
      </div>
    </div>   
  </div>
</template>
<script>
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
export default {
  name: "",

  data() {
    return {
      formData: {
        time: "",
        deviceNumber:'',
      },
      loopList:[],
      exportDialog: false,
      tableData: [
        
      ],
      stationNumber:'',
     
     
    
    };
  },
  created(){
    this.$store.commit('increment', '电力监测')
    this.$store.commit('selectChild', '低压侧')
    this.$store.commit("selectChildren", "电力运行日报");
     // this.queryOperation();
     this.stationNumber=sessionStorage.getItem('stationNumber')
    // this.stationNumber = 'ZD0279-01'
    emissionsCarbonApi.getLoopList({ stationNumber: this.stationNumber, position:3 }).then(res => {
      this.loopList = res.data
      this.formData.deviceNumber = res.data[0].deviceNumber
    this.getRunningList()
    })
  },

  mounted() {
   
  },

  methods: {

    getRunningList(){
      let obj={
        stationNumber:this.stationNumber,
        startTime:this.formData.time,
        endTime:this.formData.time,
        deviceNumber:this.formData.deviceNumber
      }
      if (!this.formData.time) {
        var date = new Date();
        date.setDate(date.getDate());
        var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        obj.startTime = yesterday
        obj.endTime = yesterday
      }
      emissionsCarbonApi.getRunningList(obj).then(res=>{
        console.log(res);
        this.tableData=res.data
      })


    },

   
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#extreme-report {
  width: 100%;
  min-width: 1621px;
  min-height: 903px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183f;
}

.main {
  width: 100%;
  min-width: 1376px;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0 8px 0 0;
}

.form :deep() .el-input {
  /* width: 132px;   */
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 滚动条样式 */

/* 更改 el-table 横向滚动条的样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding-top: 0;
}

.dialog-content {
  font-size: 16px;
  color: #c9d4f1;
  font-weight: 700;
  margin: 42px 0 64px 32px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}
::v-deep .el-table__body-wrapper {
  max-height: 75vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
